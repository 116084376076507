import { Component, Input, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, AbstractControl, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { JsonEditor, createJSONEditor, Mode } from 'vanilla-jsoneditor';
@Component({
    selector: 'aware-ui-json-editor',
    templateUrl: './json-editor.component.html',
    styleUrls: ['./json-editor.component.scss'],
    imports: [FormsModule, ReactiveFormsModule],
})
export class JsonEditorComponent implements OnDestroy, AfterViewInit {
    _control: AbstractControl;
    @Input() set control(control: AbstractControl) {
        this._control = control;
        this.initEditor();
    }
    get control(): AbstractControl {
        return this._control;
    }

    @Input() modes: Mode[] = [Mode.text, Mode.tree];

    editor: JsonEditor;

    get formControl(): UntypedFormControl {
        return this.control as UntypedFormControl;
    }

    form: UntypedFormGroup;

    @ViewChild('jsoneditor') jsonEditor: ElementRef<HTMLDivElement>;
    viewInitted$ = new BehaviorSubject(false);

    ngAfterViewInit() {
        this.viewInitted$.next(true);
    }

    ngOnDestroy() {
        if (this.editor) {
            this.editor.destroy();
        }
    }

    isValidJson(): boolean {
        return !this.editor.validate();
    }

    async initEditor() {
        const initted = await firstValueFrom(this.viewInitted$.pipe(filter((initted) => !!initted)));
        if (!initted) return;

        if (this.editor) {
            await this.editor.destroy();
        }

        const content = {
            json: this.formControl.value,
        };

        this.editor = createJSONEditor({
            target: this.jsonEditor.nativeElement,
            props: {
                content,
                mode: Mode.text,
                onChange: (updatedContent, _, { contentErrors }) => {
                    if (!contentErrors) {
                        if ((updatedContent as any).json) {
                            this.formControl.setValue((updatedContent as any).json);
                        } else if ((updatedContent as any).text) {
                            try {
                                const json = JSON.parse((updatedContent as any).text);
                                this.formControl.setValue(json);
                            } catch {}
                        }
                    }
                },
            },
        });
    }
}
